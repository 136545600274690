import * as React from 'react';
import {Sidebar, Layout } from 'react-admin';
import SideMenu from './MainSideMenu';
import MainNotifications from './MainNotification';
import { makeStyles } from '@material-ui/core/styles';
import {blueGrey} from '@material-ui/core/colors';

const useSidebarStyles = makeStyles({
    drawerPaper: {
        backgroundColor: blueGrey[800],
    },
});

const MySidebar = props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};


const MainLayout = (props) => (

    <Layout {...props} menu={SideMenu} sidebar={MySidebar} notification={MainNotifications} />
);

export default MainLayout;