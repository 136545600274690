import inMemoryJWT from './inMemoryJwt';

const apiURL = process.env.REACT_APP_API_ADDRESS

const authProvider = {
    login : ({username,password}) => {
        const request = new Request(apiURL + '/login',{
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json','Access-Control-Allow-Credentials' : 'true'}),
            credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint(apiURL + '/refresh-token');
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(function(result){
                var token = result.data.token
                var tokenExpiry = result.data.tokenExpiry
                inMemoryJWT.setToken(token,tokenExpiry);
            });
            
    },
    logout: () => {
        const request = new Request(apiURL + '/logout',{
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.ereaseToken();

        return fetch(request).then(() => '/login')
    },
    checkAuth: () => {
       // console.log('checkAuth');
        if (!inMemoryJWT.getToken()) {
            inMemoryJWT.setRefreshTokenEndpoint( apiURL + '/refresh-token');
            
            return inMemoryJWT.getRefreshedToken().then(tokenHasBeenRefreshed => {
                return tokenHasBeenRefreshed ? Promise.resolve() : Promise.reject();
            });
        } else {
            return Promise.resolve();
        }
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject()
        })
    },

}

export default authProvider;