import React,{ useEffect,}  from 'react';
import {List, Datagrid,DateField,TextField,useRefresh} from 'react-admin';
import { makeStyles  } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple,red } from '@material-ui/core/colors';
import inMemoryJWT from '../Auth/inMemoryJwt'


const useStyles = makeStyles({

    sisa : {
        color : green[500],
        
    },
    habis: { 
        color: red[500] ,
    },
})


const apiURL = process.env.REACT_APP_API_ADDRESS


const ButtonActivate = ({record = {} }) =>{
    const refresh = useRefresh();

    const aktifkan = (e,id) =>{
        e.stopPropagation();

        const token = inMemoryJWT.getToken()
        let url = apiURL + '/nsfp_activate';

        const idJSON = {id : id}
        const request = new Request(url,{
            method : 'PATCH',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body : JSON.stringify(idJSON)
        });

        
        return fetch(request)
        .then((response) => {
            if(response.status !== 200){

            }
            refresh()
        })

    }
    if(record.aktif !== 1){
        return(
            <Button onClick={e =>{aktifkan(e,record.id)}} color="primary">
                Aktifkan
            </Button>
        )
    }

    return(
        <Button disableElevation onClick={e => e.stopPropagation()} style={{backgroundColor : green[500], color : "white"}}>
            Aktif
        </Button>
    )


}
const TextSisa = (props) =>{
    const classes = useStyles();
    const {label, record} = props
    if(record.sisa > 0){
        return(
            <TextField className={classes.sisa} source="sisa" label={label} {...props}/>
        )
    }

    return(
        <TextField className={classes.habis} source="sisa" label={label} {...props}/>
    )
    
    
}
export const NSFPList = (props) =>{

    
    const classes = useStyles();

    const numberFormatOptions = {
        minimumFractionDigits : 2,
        maximumFractionDigits : 2
    } 

    const numberFormat = new Intl.NumberFormat("en-id", numberFormatOptions)

    return (
        <List {...props} title="NSFP" exporter={false} >
            <Datagrid rowClick="edit">

                <TextField source="dari_no" label="Dari" />
                <TextField source="sampai_no" label="Sampai" />
                <TextField source="last_no" label="No. Terakhir"  />
                <TextSisa label="Sisa" />
                <TextField source="total" label="Total"  />
                <DateField source="tgl_terbit" label="Tgl Terbit" locales="id" />

                <ButtonActivate />


            </Datagrid>
        </List>
    )
}