import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import {teal} from '@material-ui/core/colors'
import LabelIcon from '@material-ui/icons/Label';
import Divider from '@material-ui/core/Divider';


const SideMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div style={{paddingTop : 15}}>
            {resources.map(resource => (

                (resource.name != "Status" &&
                    <MenuItemLink
                        style={{paddingTop : 10, paddingBottom: 10,color : "white"}}
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                        }
                        leftIcon={
                            resource.icon ? <resource.icon style={{color : "white"}}/> : <DefaultIcon style={{color : "white"}} />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                )
            ))}

        </div>
    );
};

export default SideMenu;