import * as React from 'react';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { useUpdateMany,useRefresh,useNotify,useUnselectAll, } from 'react-admin';
import inMemoryJWT from '../Auth/inMemoryJwt'

const apiURL = process.env.REACT_APP_API_ADDRESS


const ButtonNSFP = ({selectedIds}) =>{
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    
    const buttonClick = (selectedIds) =>{


        const idJSON = {ids : selectedIds}


        const token = inMemoryJWT.getToken()
        let url = apiURL + '/assignnsfp';

        const request = new Request(url,{
            method : 'PATCH',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body : JSON.stringify(idJSON)
        });

        
        return fetch(request)
        .then((response) => {

            const result = response.json();
            return result
        })
        .then(function(result){

            notify(result.message)

            refresh()
        })
    }

    return(
        <div>
            <Button variant="contained" color="primary" onClick={() => buttonClick(selectedIds)}>
            Tambah No. Faktur
            </Button>
        </div>
        
    )
}

export const ButtonAssignNSFP = props => (
    <Fragment>
        <ButtonNSFP {...props}/>
        
    </Fragment>
);