import React from 'react';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import roundTo from 'round-to'
import moment from 'moment'
import inMemoryJWT from '../Auth/inMemoryJwt'

const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

const sleep = (ms) =>{
    var start = new Date().getTime(), expire = start + ms;
    while (new Date().getTime() < expire) { }
    return;
}

const loadFaktur = async(apiURL,token, id) => {
    let url = apiURL + '/fakturpenjualan/' + id;

    const request = new Request(url,{
        method : 'GET',
        headers : new Headers({
            'Content-Type' : 'application/json',
            'Authorization': `Bearer ${token}`
        }),
    });

    return await (await fetch(request)).json();

}

export const ExporterEfakturCSV = async (list_faktur_obj,udpateProcessing,handleClose) =>{

    console.log(list_faktur_obj)
    const apiURL = process.env.REACT_APP_API_ADDRESS

    var fakturToExport = []

    let i = 0
    var list_faktur = []
    
    for(var key in list_faktur_obj){
        list_faktur.push(list_faktur_obj[key])
    }
    /*
    let list_faktur = Object.keys(list_faktur_obj).map(e=>{
        let ret = {};
        ret.push(list_faktur_obj[e]);
        ret[i].id = e.toString()
        i++
        return ret;
    });*/

    //console.log(list_faktur)
    
    var header2 = {}
    header2.FK = "LT"
    header2.KD_JENIS_TRANSAKSI = "NPWP"
    header2.FG_PENGGANTI = "NAMA"
    header2.NOMOR_FAKTUR = "JALAN"
    header2.MASA_PAJAK = "BLOK"
    header2.TAHUN_PAJAK = "NOMOR"
    header2.TANGGAL_FAKTUR = "RT"
    header2.NPWP = "RW"
    header2.NAMA = "KECAMATAN"
    header2.ALAMAT_LENGKAP = "KELURAHAN"
    header2.JUMLAH_DPP = "KABUPATEN"
    header2.JUMLAH_PPN = "PROPINSI"
    header2.JUMLAH_PPNBM = "KODE_POS"
    header2.ID_KETERANGAN_TAMBAHAN = "NOMOR_TELEPON"
    header2.FG_UANG_MUKA = ""
    header2.UANG_MUKA_DPP = ""
    header2.UANG_MUKA_PPN = ""
    header2.UANG_MUKA_PPNBM = ""
    header2.REFERENSI = ""
    header2.KODE_DOKUMEN_PENDUKUNG = ""

    var header3 = {}
    header3.FK = "OF"
    header3.KD_JENIS_TRANSAKSI = "KODE_OBJEK"
    header3.FG_PENGGANTI = "NAMA"
    header3.NOMOR_FAKTUR = "HARGA_SATUAN"
    header3.MASA_PAJAK = "JUMLAH_BARANG"
    header3.TAHUN_PAJAK = "HARGA_TOTAL"
    header3.TANGGAL_FAKTUR = "DISKON"
    header3.NPWP = "DPP"
    header3.NAMA = "PPN"
    header3.ALAMAT_LENGKAP = "TARIF_PPNBM"
    header3.JUMLAH_DPP = "PPNBM"
    header3.JUMLAH_PPN = ""
    header3.JUMLAH_PPNBM = ""
    header3.ID_KETERANGAN_TAMBAHAN = ""
    header3.FG_UANG_MUKA = ""
    header3.UANG_MUKA_DPP = ""
    header3.UANG_MUKA_PPN = ""
    header3.UANG_MUKA_PPNBM = ""
    header3.REFERENSI = ""
    header3.KODE_DOKUMEN_PENDUKUNG = ""

    fakturToExport.push(header2)
    fakturToExport.push(header3)


    //LOOP THROUGH EACH INVOICE 
    for(let i = 0; i < list_faktur.length;i++){
        var faktur = list_faktur[i];
        sleep(1000);

        udpateProcessing(faktur.transaction_no)
        //console.log(faktur)

        //GET TRANSACTION LINES

        const token = inMemoryJWT.getToken()
        let url = apiURL + '/fakturpenjualan/' + faktur.id;

        /*const request = new Request(url,{
            method : 'GET',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
        });

        let response = await fetch(request)
        let sales_invoice = await response.json()
        */

        let sales_invoice = await loadFaktur(apiURL,token,faktur.id)
        
        if (sales_invoice.status === "error" && sales_invoice.message.message === "Request failed with status code 429"){
            
            for(let j = 0; j < 10; j++){
                sleep(3000)
                sales_invoice = await loadFaktur(apiURL,token,faktur.id)

                if(sales_invoice.status !== "error"){
                    break;
                }

                if(j === 4){
                    handleClose()
                    alert("Terjadi kesalahan saat mengambil data faktur")
                }
            }
        }

        if (sales_invoice.status == "not found"){
            continue;
        }
        var transaction_lines = sales_invoice.data.sales_invoice.transaction_lines_attributes

        //LOOP THROUGH TRANSACTION LINES
        var total_dpp = 0
        var total_ppn = 0

        var array_transaction_lines = []
        for(let r = 0;r < transaction_lines.length; r++){

            var line = transaction_lines[r]
            //console.log(line)

            var harga_satuan = roundTo(parseFloat(line.rate),2)

            var quantity_fixed = line.quantity.toFixed()
            var quantity = parseInt(quantity_fixed)
            
            var dpp = roundTo(parseFloat(line.amount),2)
            var dpp_string = dpp || "0"

            var diskon = roundTo(parseFloat(line.discount),2)

            var jumlah_diskon = ((diskon /100) * harga_satuan) * quantity
            jumlah_diskon = roundTo(jumlah_diskon,2)

            var harga_total = harga_satuan * quantity
            var harga_total_string = harga_total || "0"
            
            var ppn = 0.11 * dpp
            ppn = roundTo(ppn,2)
            var ppn_string = ppn || "0"
            
            total_dpp += dpp
            total_ppn += ppn

            var of = {}
            of.FK = "OF"
            of.KD_JENIS_TRANSAKSI = line.product.code //KODE_OBJEK 
            of.FG_PENGGANTI =  line.product.name//NAMA
            of.NOMOR_FAKTUR = harga_satuan // HARGA_SATUAN
            of.MASA_PAJAK = quantity_fixed //JUMLAH_BARANG
            of.TAHUN_PAJAK =  harga_total_string//HARGA_TOTAL 
            of.TANGGAL_FAKTUR = jumlah_diskon.toFixed() //DISKON
            of.NPWP = dpp_string //DPP
            of.NAMA = ppn_string//PPN
            of.ALAMAT_LENGKAP = "0" //TARIF_PPNBM 
            of.JUMLAH_DPP = "0" //PPNBM
            of.JUMLAH_PPN = "" 
            of.JUMLAH_PPNBM = "" 
            of.ID_KETERANGAN_TAMBAHAN = ""
            of.FG_UANG_MUKA = ""
            of.UANG_MUKA_DPP = ""
            of.UANG_MUKA_PPN = ""
            of.UANG_MUKA_PPNBM = ""
            of.REFERENSI = ""
            of.KODE_DOKUMEN_PENDUKUNG = ""

            array_transaction_lines.push(of)
            //console.log(line.product.name)
           // console.log(harga_total)
            
        }


        total_dpp = roundTo.down(total_dpp,0)

        //get decimal value of total_dpp and total_ppn
        var total_ppn_decimal = total_ppn - Math.floor(total_ppn)

        total_ppn = roundTo.down(total_ppn,0)

        /*
        if(total_ppn_decimal >= 0.98){
            total_ppn = Math.ceil(total_ppn)
        }else{
            total_ppn = roundTo.down(total_ppn,0)
        }
        */
        
        var date_trans = moment(faktur.tanggal_transaksi).format("DD/MM/YYYY")
        var month_trans = moment(faktur.tanggal_transaksi).format("MM")
        var month_year = moment(faktur.tanggal_transaksi).format("YYYY")

        var nsfp_ori = parseInt(faktur.nsfp)
        var nsfp_padded = pad(nsfp_ori,13)
        //FK FAKTUR KELUARAN
        var fk = {}
        fk.FK = "FK"
        fk.KD_JENIS_TRANSAKSI = "01"
        fk.FG_PENGGANTI = "0"
        fk.NOMOR_FAKTUR = nsfp_padded
        fk.MASA_PAJAK = month_trans
        fk.TAHUN_PAJAK = month_year
        fk.TANGGAL_FAKTUR = date_trans
        fk.NPWP =faktur.npwp.split('.').join("").split("-").join("");
        fk.NAMA = faktur.perusahaan
        fk.ALAMAT_LENGKAP = faktur.alamat_billing.replace(/(\r\n|\n|\r)/gm, "");
        fk.JUMLAH_DPP = total_dpp
        fk.JUMLAH_PPN = total_ppn
        fk.JUMLAH_PPNBM = "0"
        fk.ID_KETERANGAN_TAMBAHAN = ""
        fk.FG_UANG_MUKA = "0"
        fk.UANG_MUKA_DPP = "0"
        fk.UANG_MUKA_PPN = "0"
        fk.UANG_MUKA_PPNBM = "0"
        fk.REFERENSI = faktur.transaction_no
        fk.KODE_DOKUMEN_PENDUKUNG = ""
        fakturToExport.push(fk)


        //LT LAWAN TRANSAKSI
        var lt = {}
        lt.FK = "LT"
        lt.KD_JENIS_TRANSAKSI = faktur.npwp.split('.').join("").split("-").join("") //NPWP 
        lt.FG_PENGGANTI = faktur.perusahaan //NAMA
        lt.NOMOR_FAKTUR = faktur.alamat_billing.replace(/(\r\n|\n|\r)/gm, ""); // JALAN
        lt.MASA_PAJAK = "" //BLOK
        lt.TAHUN_PAJAK = "" //NOMOR
        lt.TANGGAL_FAKTUR = ""//RT
        lt.NPWP = "" //RW
        lt.NAMA = "" //KECAMATAN
        lt.ALAMAT_LENGKAP = "" //KELURAHAN 
        lt.JUMLAH_DPP = "" //KABUPATEN
        lt.JUMLAH_PPN = "" //PROPINSI
        lt.JUMLAH_PPNBM = "" //KODE_POS
        lt.ID_KETERANGAN_TAMBAHAN = sales_invoice.data.sales_invoice.person.phone //NOMOR TELEPON
        lt.FG_UANG_MUKA = ""
        lt.UANG_MUKA_DPP = ""
        lt.UANG_MUKA_PPN = ""
        lt.UANG_MUKA_PPNBM = ""
        lt.REFERENSI = ""
        lt.KODE_DOKUMEN_PENDUKUNG = ""
        
        fakturToExport.push(lt)

        fakturToExport.push.apply(fakturToExport,array_transaction_lines)
        
    } 



    var filename = moment(list_faktur[0].tanggal_transaksi).format("YYYYMMDD")
    jsonExport(fakturToExport,{
        headers : ['FK','KD_JENIS_TRANSAKSI','FG_PENGGANTI','NOMOR_FAKTUR','MASA_PAJAK','TAHUN_PAJAK','TANGGAL_FAKTUR','NPWP','NAMA','ALAMAT_LENGKAP','JUMLAH_DPP','JUMLAH_PPN','JUMLAH_PPNBM','ID_KETERANGAN_TAMBAHAN','FG_UANG_MUKA','UANG_MUKA_DPP','UANG_MUKA_PPN','UANG_MUKA_PPNBM','REFERENSI','KODE_DOKUMEN_PENDUKUNG']
    }, (err,csv)=>{
        downloadCSV(csv,filename)
    })

    await handleClose()
    
}