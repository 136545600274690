import * as React from 'react';
import { useState, useEffect } from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import inMemoryJWT from '../Auth/inMemoryJwt'

import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps,
    useDataProvider

} from 'react-admin';

import {ExporterEfakturCSV} from  '../CustomComponents/ExporterEfakturCSV'
import IconEvent from '@material-ui/icons/Event';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const apiURL = process.env.REACT_APP_API_ADDRESS



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection : "column"
  },
}));


export const ToopToolbarFaktur = (props) => {
    const {
        data,
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const dataProvider = useDataProvider();

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [transNo, setTransNo] = React.useState("");

    const [nsfpActive,setNsfpActive] = React.useState({
        dari_no : "",
        sampai_no : ''
    })

    useEffect(() => {

        const token = inMemoryJWT.getToken()
        let url = apiURL + '/nsfp_getactive';

        const request = new Request(url,{
            method : 'GET',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
        });

        
        fetch(request)
        .then((response) => {

            const result = response.json();
            return result
        })
        .then(function(result){
            setNsfpActive(result[0])
        })

    },[])


    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
    setOpen(!open);
    };

    const udpateProcessing = (transNo) =>{
        setTransNo(transNo)

    }

    const param = {
        pagination : {
            page : 1,
            perPage : 2000
        },
        sort : currentSort,
        filter:filterValues
    }
    const onExportClick = (data) => {

        dataProvider.getList('fakturpenjualan',param)
        .then(response => {
            console.log(response)
            handleToggle()
            ExporterEfakturCSV(response.data, udpateProcessing,handleClose)
        })

        
       
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            
            <div style={{marginRight : 75 }}>
                <Typography variant='h6' color="primary">NSFP AKTIF</Typography>
                <Typography variant='subtitle2'>{nsfpActive.dari_no + " - " + nsfpActive.sampai_no}</Typography>
            </div>

            <Button
                variant="contained"
                size="large"
                onClick={() => {onExportClick(data)}}
                label="EXPORT"
            />
            {/*
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />*/}
             <Backdrop className={classes.backdrop} open={open} >
                <CircularProgress color="inherit" />
                <br></br>
                <h3>{transNo}</h3>
            </Backdrop>
        </TopToolbar>
    );
};

