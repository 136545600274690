import React  from 'react';
import { Edit, TextInput} from 'react-admin';
import {ToolbarEditView} from '../CustomComponents/ToolbarEditView'
import {SimpleFormWithDefaults} from '../CustomComponents/SimpleFormWithDefaults'
import {DateInput} from '../DatePicker'

import moment from 'moment'



const CustomForm = props => {

    const data =props.data
    return(
        <div>
            <h4  style={{marginBottom:2}}>No. Faktur</h4>

            <div  style={{display : "flex", width : "100%"}}>
                <TextInput  source="dari_no"/>
                <span style={{fontSize: 40,marginLeft : 20,marginRight : 20}}>-</span>
                <TextInput  source="sampai_no" />
            </div>

            <h4  style={{marginBottom:2}}>No. Terakhir</h4>
            <TextInput  source="last_no"  />
            
            <h4  style={{marginBottom:2}}>Sisa / Total</h4>
            <p>{data.sisa + " / " + data.total}</p>

            <h4  style={{marginBottom:2}}>Tanggal Terbit</h4>
            <DateInput  source="tgl_terbit"  defaultValue={data.tgl_terbit} options={{ format: 'dd/MM/yyyy' }}/>
        </div>
    )
}




export const NSFPEdit = (props) => {

    return(
    <Edit {...props} undoable={false}  actions={<ToolbarEditView />}>
        <SimpleFormWithDefaults {...props}>
            <CustomForm />
        </SimpleFormWithDefaults>

    </Edit>
    )
}